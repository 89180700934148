import {Model} from "@vuex-orm/core";
// import User from "../../user/services/User.js";

export default class BillingAccount extends Model {
  static entity = 'BillingAccount'

  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    baseURL: '/api',
    dataKey: 'billingAccounts',
    actions: {
      fetch: {
        method: 'get',
        url: 'recipient-accounts'
      }
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      user: this.attr(''),
      text: this.attr(''),
      company: this.attr(null),
      address: this.attr(null)
    }
  }
}