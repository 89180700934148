import {throttle} from "../utils/TimeExecution.js";
import { ElNotification } from 'element-plus'
import Auth from "../modules/auth/services/Auth.js";

export default {
  defaultOrderBy: {
    order: 'desc',
    prop: 'id'
  },
  // loadingOnce: true,
  data () {
    return {
      response: null,
      token_csrf: null,
      lastRouteName: '',
      isLoading: false,
      countCall: 0,
      responseData: null,
      // currentPage: 1,
      paginationQuery: {
        query: {},
        count: 0,
        page: 0,
        countPage: 0
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      async handler (route, oldRoute) {
        if (route) {
          try {
            if (this.fetchMode === 'watch') {
              await this.beforeDataFetch(route)
            } else if (this.fetchMode === 'beforeRouteEnter') {
              if (oldRoute && oldRoute.name === route.name) {
                await this.beforeDataFetch(route)
              }
            }
          } catch (e) {
            console.error("Can't execute beforeDataFetch")
            console.error(e)
          }
        }
      }
    }
  },
  async mounted() {
  },
  computed: {
    isLoadingContent () {
      return this.isLoading && this.modelList.length === 0
    },
    lasyLoading () {
      return true
    },
    fetchMode () {
      return 'beforeRouteEnter'
      // return 'watch'
    },
    fetchUrl () {
      return this.$options.fetchUrl
    },
    loadUrl () {
      return this.$route ? this.$route.name : ''
    },
    Auth () {
      return Auth.query().get().length ? Auth.query().get()[0] : false
    },
    lastLoadedIds () {
      return this.responseData.map(i => i.id)
    },
    isAllLoadedModel () {
      return this.queryWithoutPagination.get().length >= this.paginationQuery.count
    },
    queryWithoutPagination () {
      const query = this.Model.query()
      this.preQuery(query)
      this.applySearchQuery(query)
      this.applySortQuery(query)
      return query
    },
    modelListWithoutPagination () {
      return this.queryWithoutPagination.get()
    },
    fullQuery () {

      const query = this.Model.query()
      this.preQuery(query)
      this.applySearchQuery(query)
      this.applyPaginationQuery(query)
      this.applySortQuery(query)
      return query
    },
    modelList () {
      return this.fullQuery.get()
    },
    sortData () {
      if (this.$route && this.$route.query.s) {
        return this.$route.query.s.split('-')
      } else {
        return false
      }
    },
    search: {
      set (val) {
        this.addQuery({
          q: val
        })
      },
      get () {
        return this.$route.query.q ? this.$route.query.q : ''
      }
    },
    perPage () {
      return 10
    },
    currentPage: {
      get () {
        return this.$route && this.$route.query.p ? parseInt(this.$route.query.p) : 1
      },
      set (val) {
        this.changePage(val)
      }
    },
    mode: {
      set (val) {
        if (val === 'tous') {
          val = false
        }
        this.addQuery({f: val})
      },
      get () {
        return this.$route && this.$route.query.f ? this.$route.query.f : 'tous'
      }
    },
    tableSortData () {
      const sort = {}
      if (this.sortData && this.sortData.length === 2) {
        sort['prop'] = this.sortData[0]
        sort['order'] = this.sortData[1] === 'desc' ? 'descending' : 'ascending'
      }
      return sort
    },
    additionalParams () {
      return {}
    },
    offset () {
      return (this.currentPage - 1) * this.perPage
    }
  },
  methods: {
    handleBeforeRouteEnter(to, from, next) {
      next(async (vm) => {
        try {
          if (vm.fetchMode === 'beforeRouteEnter') {
            await vm.beforeDataFetch()
          }
        } catch (e) {
          console.error("Can't execute beforeDataFetch")
          console.error(e)
        }
      })
    },
    applyPaginationQuery (query) {
      if (Array.isArray(this.responseData) && this.responseData.length && !this.isAllLoadedModel && this.lasyLoading) {
        query.whereIdIn(this.lastLoadedIds)
      } else {
        const offset = this.offset
        query.offset(offset).limit(this.perPage)
      }
    },
    applySearchQuery (query) {
      console.warn('search query todo')
    },
    applySortQuery (query) {
      if (this.sortData) {
        query.orderBy(this.sortData[0], this.sortData[1])
      } else {
        query.orderBy('id', 'desc')
      }
    },
    preQuery (query) {
    },
    searchQuery (query) {
    },
    changePage (page) {
      this.addQuery({
        p: page
      })
    },
    addQuery (q) {
      if (this.$route) {
        const query = {
          ...this.$route.query,
          ...q
        }
        if (q.q === '') {
          delete query.q
        }
        if (q.s === false) {
          delete query.s
        }
        if (q.f === false) {
          delete query.f
        }
        this.$router.push({
          query
        })
      }
    },
    sortChange (payload) {
      console.log('sortChange')
      if (payload.order) {
        const order = payload.order === 'ascending' ? 'asc' : 'desc'
        const sort = `${payload.prop}-${order}`
        this.addQuery({
          s: sort
        })
      } else {
        this.addQuery({
          s: false
        })
      }
    },
    async beforeDataFetch (route) {
      if (!this.$options.loadingOnce || this.countCall < 1) {
        try {
          if (this.loadUrl) {
            this.lastRouteName = this.loadUrl + ''
          }
          this.isLoading = true
          this.countCall++
          return await this.fetchData()
        } catch (e) {
          console.log('before data fetch error')
          console.error(e)
        } finally {
          this.isLoading = false
        }
      } else if (this.lastRouteName === this.$route.name) {
        return await this.fetchData()
      }
    },
    fetchDataThrottle: throttle(function () {
      this.fetchData()
        .then(() => {
        })
        .catch((e) => {
        console.error(e)
      })
    }, 1000, true, true),
    handleResponse (rep) {
      if (rep.response && rep.response.data) {
        this.response = rep.response.data
      }
      if (rep.response.data[rep.config.dataKey]) {
        this.responseData = rep.response.data[rep.config.dataKey]
      }
      // console.log(rep.response.data['token_csrf'])
      if (rep.response.data['token_csrf']) {
        this.token_csrf = rep.response.data.token_csrf
      }
      if (rep.response.data.metadatas) {
        if (rep.response.data.metadatas['count']) {
          rep.response.data.metadatas['count'] = parseInt(rep.response.data.metadatas['count'])
        }
        if (rep.response.data.metadatas['countPage']) {
          rep.response.data.metadatas['countPage'] = parseInt(rep.response.data.metadatas['countPage'])
        }
        if (rep.response.data.metadatas['page']) {
          rep.response.data.metadatas['page'] = parseInt(rep.response.data.metadatas['page'])
        }
        this.paginationQuery = rep.response.data.metadatas
        this.countBills = parseInt(rep.response.data.count)
        this.countPage = rep.response.data.countPage
        this.currentLoadingPage = rep.response.data.page

      }
      return rep
      // ElNotification({
      //   title: 'Warning',
      //   message: 'This is a warning message',
      //   type: 'warning',
      //   duration: 0,
      // })
      // console.log('el notif')
    },
    async fetchData () {
      let url = null
      let handleResponse = null
      if (typeof this.fetchUrl === 'string') {
        url = this.fetchUrl
      }
      if (this.fetchUrl)
      this.loadedData = []
      try {
        let params = {}
        if (this.$route) {
          params = {...this.$route.query, ...this.additionalParams }
        }
        let response = await this.Model.api().get(url, {
          params
        })
        handleResponse =  this.handleResponse(response)
        if (typeof this.afterFetchSuccess === 'function') {
          try {
            await this.afterFetchSuccess(handleResponse)
          } catch (error) {
            console.error(error)
          }
        }
        return handleResponse
      } catch (e) {
        if (e && e.name && e.message) {
          ElNotification({
            title: 'Error :' + e.name,
            message: e.message,
            type: 'error',
          })
          throw new Error(e)
        }
      }
      return handleResponse
    }
  }
}