<template>
  <div class="form-row">
    <div class="form-group col-md-8 pr-md-3 availability-select">
      <div class="depot-sectioin-title" @click="fetchData">
        <h3>{{$t('change-facturation.title')}}</h3>
      </div>
      <label for="invoice-account">
      </label>
        <input type="hidden" name="invoiceRecipientAccountId" v-model="edit.InvoiceAccount">
      <el-select name="invoiceRecipientAccount" v-model="edit.invoiceRecipientAccount" class="add-account old-ui" placeholder="Select" size="large" :value="edit.invoiceRecipientAccount">
        <el-option
            v-for="opt in InvoiceAccountsSelect"
            :key="opt.value"
            :label="opt.text"
            :value="opt.value"
        />
      </el-select>
      <div v-show="edit.invoiceRecipientAccount === 'new'">
        <AddBillingAccount v-model="newAccount" @edit="onEditNewInvoiceAccount"/>
      </div>
    </div>
  </div>
  <el-button size="large" type="primary" plain class="" :loading="saveLoading" href="#" @click="onSaveBillingAccount">
    {{ $t('change-facturation.button') }}
  </el-button>
</template>

<script>
import loadContent from "../../../mixins/loadContent.js";
import BillingAccount from "../services/BillingAccount.js";
import AddBillingAccount from "./AddBillingAccount.vue";
import {transformDataToFormContent} from "~/services/parameters.js";
import Rental from "~/modules/ask/services/Rental.js";

export default {
  name: "ChangeRecipientAccount",
  components: {AddBillingAccount},
  fetchUrl: 'recipient-accounts',
  mixins: [loadContent],
  data () {
    return {
      saveLoading: false,
      edit: {
        invoiceRecipientAccount: this.$symfony.defaultInvoiceAccount.value
      },
      newAccount: false
    }
  },
  async mounted() {
    if (!this.$route) {
      try {
        await this.beforeDataFetch()
      } catch(e) {
        console.error(e)
      }
    }
  },
  props: {
    rentalId: {
      type: Number,
      default: null
    },
    showSaveButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async onSaveBillingAccount ($event) {
      $event.preventDefault()
      console.log('onSaveBillingAccount')
      let data = {
        invoiceRecipientAccountId: this.edit.invoiceRecipientAccount,
      }
      if (this.newAccount) {
        if (this.newAccount.isCompany) {
          data = {
            ...data,
            ...this.newAccount.company,
            ...this.newAccount.company.address,
            isCompany: 'on'
          }
          delete data.address
        } else {
          data = {
            ...data,
            ...this.newAccount,
            ...this.newAccount.address,
            isCompany: 'off'
          }
        }
      }

      await this.sendData(data, false)
    },
    onEditNewInvoiceAccount (val) {
      this.newAccount = val
      console.log('edit new invoice account')
      console.log(val)
    },
    async sendData (data, form = 'fos_user_registration_form') {
      if (this._token) {
        data['_token'] = this._token
      }
      let dataFormContent = {}
      if (form) {
        dataFormContent[form] = {...data}
      } else {
        dataFormContent = {...data}
      }
      let dataToSend = transformDataToFormContent(dataFormContent, [])
      let url = '/api/user/save-tenant-billing-account/'
      if (this.rentalId) {
        url += this.rentalId
      }
      this.saveLoading = true
      try {
        const response = await Rental.api().post(url, dataToSend, {
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          }
        })
        this.saveLoading = false
        if (response && response.response && response.response.data) {
          if (response && response.response && response.response.data && response.response.data.form_errors) {
            this.formErrors = response.response.data.form_errors
          }
          if (response.response.data.token_csrf) {
            this._token = response.response.data.token_csrf
          }
          if (response.response.data.error) {
            this.error = response.response.data.error
          }
          if (response.response.data.csrf_token_valid === false) {
            this.generalError = {
              title: 'Le token csrf est expiré',
              description: 'Veuillez recharger la page et réessayer.'
            }
          } else {
            this.generalError = false
          }
          if (response.response.data.invoiceRecipientAccountSelected) {
            console.log('invoiceRecipientAccountSelected')
            this.$symfony.defaultInvoiceAccount = response.response.data.invoiceRecipientAccountSelected
          }
          if (response.response.data.billingAccount) {
            await BillingAccount.insertOrUpdate({
              data: response.response.data.billingAccount
            })
          }
          if (response.response.data.rental) {
            await Rental.insertOrUpdate({
              data: response.response.data.rental
            })
          }
          this.$emit('addressChanged')
        }
        return response
      } catch (e) {
        console.error(e)
        if (e.response && e.response.data && e.response.data.redirect_url) {
          window.location.href = e.response.data.redirect_url
        }
        if (e.response && e.response.data && e.response.data.error) {
          this.error = e.response.data.error
        }
        // console.log(response)
        this.saveLoading = false
      }
    }
  },
  computed: {
    invoiceAccountId () {
      return this.edit.invoiceRecipientAccount
    },
    Model () {
      return BillingAccount
    },
    InvoiceAccountsSelect () {
      let text = ''
      let newValue = {
        text: 'Ajouter une adresse de facturation',
        value: 'new'
      }
      if (this.newAccount) {
        if (this.newAccount.isCompany) {

          text = this.newAccount.company && this.newAccount.company.name ? this.newAccount.company.name : ''
          let address = this.newAccount.company && this.newAccount.company.addressName ? this.newAccount.company.addressName : ''
          if (text.length > 0) {
            text = text + ' - ' + address
          } else {
            text = text + address
          }
          newValue = {
            text,
            value: 'new'
          }
        } else {
          let nom = this.newAccount.firstname ? this.newAccount.firstname : ''
          let prenom = this.newAccount.lastname ? this.newAccount.lastname : ''
          text = nom + ''
          if (text.length > 0) {
            text = text + ' '
          }
          text = text + prenom
          if (Object.keys(this.newAccount.address).length) {
            let adresse = ''
            if (this.newAccount.address.numero && this.newAccount.address.rue) {
              adresse = this.newAccount.address.numero + ' ' + this.newAccount.address.rue + ', '
            } else if (this.newAccount.address.adresse) {
              adresse = this.newAccount.address.adresse + ', '
            }
            text = text + ' - ' + adresse + this.newAccount.address.codePostal
              + ' ' + this.newAccount.address.ville + ', ' + this.newAccount.address.pays
          }
        }
        if (text.length === 0) {
          text = 'Ajouter une adresse de facturation'
        }
        newValue = {
          text: text,
          value: 'new'
        }
        // newValue = {
        //   text: this.edit.newAccount.address.firstname + ' ' + this.edit.newAccount.address.lastname,
        //   value: this.edit.newAccount.id
        // }
      }
      return [
          ...this.UserInvoiceAccounts,
        {
          ...newValue
        }
      ]
    },
    UserDefaultAccount () {
      return this.$symfony.defaultInvoiceAccount && this.$symfony.defaultInvoiceAccount.text
    },
    UserInvoiceAccounts () {
      if (!Array.isArray(this.modelList) && !this.UserDefaultAccount) {
        return []
      } else if (!this.modelList.length && this.UserDefaultAccount) {
        return [{
          text: this.$symfony.defaultInvoiceAccount.text,
          value: this.$symfony.defaultInvoiceAccount.value
        }]
      }
      return this.modelList.map((invoiceAccount) => {
        return {
          text: invoiceAccount.text,
          value: invoiceAccount.id
        }
      })
    }
  }
}
</script>

<style>
  .old-ui .el-input__wrapper {
      box-sizing: border-box;
      font-size: 16px;
      font-family: "Nunito Sans", sans-serif;
      font-weight: 600;
      height: 50px;
  }
  .old-ui .el-input__wrapper input{
      height: 24px;
      color: black;
      font-size: 16px;
      font-weight: 600;
  }
  .add-account {
    width: 100%;
    max-width: 445px;
  }
</style>