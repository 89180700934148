<template>
  <label for="use-personnal-adress" class="form-checkbox-label mb-3">
    <div class="custom-checkbox">
      <input
        type="checkbox"
        name="isCompany"
        id="use-personnal-adress"
        v-model="edit.isCompany"
      />
      <span class="check"><i class="fas fa-check"></i></span>
    </div>
    <span v-html="'Compte professionnel'"></span>
  </label>
  <AddCompany v-show="edit.isCompany" @edit="onEdit" />
  <div v-show="!edit.isCompany">
    <div class="form-group">
      <label for="firstname">
        Prenom
      </label>
      <el-input
        name="firstname"
        type="text"
        id="firstname"
        size="large"
        class="old-ui"
        v-model="edit.firstname"
      />
    </div>
    <div class="form-group">
      <label for="lastname">
        Nom
      </label>
      <el-input
        name="lastname"
        type="text"
        id="lastname"
        size="large"
        class="old-ui"
        v-model="edit.lastname"
      />
    </div>

    <div class="form-group">
      <input-city
        :label="'Adresse de facturation'"
        v-model="edit.address"
        placeholder="24 rue mouftard 75008 Paris"
        :manual-mode="true"
      />
      <!--        <input type="text" id="autocomplete" onfocus="initAutocomplete()" name="secondaryAdress" maxlength="255" class="form-control " placeholder="12 rue du réalisateur, 75012 Paris, France" value="{% if content.adresseGeoloc.certifie is defined and content.adresseGeoloc.certifie %}{{ content.adresseGeoloc.numero }} {{ content.adresseGeoloc.rue }}, {{ content.adresseGeoloc.codePostal }} {{ content.adresseGeoloc.ville }}{% elseif addresse.certifie is defined and addresse.certifie %}{{ addresse.codePostal }}{% endif %}">-->
      <div class="invalid-feedback"></div>
      <input v-for="hf in hiddenFields"  type="hidden" :name="hf.name" v-model="hf.value">
    </div>
  </div>
</template>

<script>
// import formHandler from "../../../mixins/formHandler.js";
import InputCity from "../../../components/parameters/inputCity.vue";
import AddCompany from "./AddCompany.vue";

export default {
  name: "AddBillingAccount",
  components: { AddCompany, InputCity },
  // mixins: [formHandler],
  data() {
    return {
      contentLoaded: false,
      edit: {
        isCompany: false,
        company: {
          siret: ""
        },
        address: {},
        firstname: "",
        lastname: ""
      },
      content: {
        company: {
          siret: ""
        },
        address: {
          firstname: "",
          lastname: ""
        }
      }
    };
  },
  watch: {
    edit: {
      handler: function(val, oldVal) {
        this.$emit("edit", val);
      },
      deep: true
    }
  },
  computed: {
    hiddenFields () {
      return Object.keys(this.edit.address).map(key => {
        return {
          name: key,
          value: this.edit.address[key]
        }
      })
    }
  },
  methods: {
    onEdit(val) {
      if (val) {
        // console.log(val)
        if (val && val.company) {
          this.edit.company = val.company;
          // this.$emit('edit', JSON.parse(JSON.stringify(this.edit)))
        }
      }
    }
  }
};
</script>

<style scoped></style>
