<template>
  <div>
    <div class="form-group" v-if="siret || vat || editCompanyName">
      <label for="">Nom société</label>
      <el-input
        v-if="siret"
        name="companyName"
        v-model="siret.nom_entreprise"
        size="large"
        class="old-ui"
        disabled
      />
      <el-input
        v-else-if="vat && !vat.valid"
        name="companyName"
        v-model="edit.company.name"
        class="old-ui"
        size="large"
      />
      <el-input
        v-else-if="vat"
        name="companyName"
        v-model="vat.name"
        class="old-ui"
        size="large"
        disabled
      />
      <el-input
        v-else-if="editCompanyName"
        name="companyName"
        v-model="edit.company.name"
        class="old-ui"
        size="large"
      />
    </div>
    <div class="form-group">
      <label for="">Numéro Siret</label>
      <el-input
              name="siret"
        v-model="edit.company.siret"
        @input="onSiretChange"
        size="large"
        class="old-ui"
      >
          <template #suffix>
              <span v-show="numberOfSiretRequest > 0">loading ...</span>
          </template>
      </el-input>
    </div>
      <label class="form-group form-checkbox-label ">
        <div class="custom-checkbox">
            <input
                    type="checkbox"
                    name="hasVat"
                    id="hasVat"
                    v-model="edit.hasVat"
            />
            <span class="check"><i class="fas fa-check"></i></span>
        </div>
        Je suis assujetti à la TVA
      </label>

    <div v-show="edit.hasVat" class="form-group">
      <label for="">Numéro TVA intracommunautaire</label>
      <el-input
        v-if="!siret"
        name="taxId"
        v-model="edit.company.taxId"
        @input="onVATidChange"
        size="large"
        class="old-ui"
      ></el-input>
      <el-input
        v-else
        name="taxId"
        v-model="siret.numero_tva_intracommunautaire"
        size="large"
        class="old-ui"
        disabled
      />
    </div>
    <div class="form-group">
      <label for="">Siège</label>
      <input-city
        v-if="!siret && !vat"
        name="siege"
        v-model="edit.company.address"
        size="large"
        class="old-ui"
        placeholder="24 rue mouftard 75008 Paris"
      ></input-city>
      <input-city
          name="siege"
          v-else-if="vat && !vat.valid"
          v-model="edit.company.address"
          placeholder="24 rue mouftard 75008 Paris"
      />
      <el-input
        v-else-if="vat"
        name="siege"
        v-model="siretAddress"
        size="large"
        class="old-ui"
        disabled
      ></el-input>
      <el-input
        v-else
        name="siege"
        v-model="siretAddress"
        size="large"
        class="old-ui"
        disabled
      />
    </div>
      <input v-if="siretAddress" name="siege" type="hidden" :value="siretAddress" />
      <input v-if="vat && vat.countryCode" type="hidden" name="country_code" v-model="vat.countryCode">
    <input v-for="hf in hiddenFields"  type="hidden" :name="hf.name" v-model="hf.value">
  </div>
</template>

<script>
import { throttle } from "../../../utils/TimeExecution.js";
import { loadPageContent } from "../../../services/parameters.js";
import InputCity from "~/components/parameters/inputCity.vue";

export default {
  name: "AddCompany",
  components: {InputCity},
  // mixins: [loadContent],
  fetchUrl: "analyze-siret",
  props: {
  },
  data() {
    return {
      numberOfSiretRequest: 0,
      siret: false,
      vat: false,
      edit: {
        hasVat: true,
        company: {
          name: "",
          siret: "",
          taxId: "",
          addressName: "",
          address: {}
        },
        address: {}
      },
      content: {
        company: {
          siret: ""
        },
        addressName: "",
        address: {}
      }
    };
  },
  watch: {
    edit: {
      handler: function(val, oldVal) {
        this.$emit("edit", val)
      },
      deep: true
    }
  },
  computed: {
    hiddenFields () {
      return Object.keys(this.edit.company.address).map(key => {
        let value = null
        if (typeof this.edit.address[key] !== 'undefined') {
          value = this.edit.address[key]
        } else {
          value = this.edit.company.address[key]
        }
        return {
          name: key,
          value: value
        }
      })
    },
    editCompanyName() {
      return this.edit.company.siret && this.edit.company.siret.length > 3 && this.edit.company.taxId && this.edit.company.taxId.length > 2 ;
    },
    siretAddress() {
      if (this.siret) {
        let adresse = this.siret.siege && this.siret.siege.adresse_ligne_1 ? this.siret.siege.adresse_ligne_1 : ''
        let codePostal = this.siret.siege && this.siret.siege.code_postal ? this.siret.siege.code_postal : ''
        let ville = this.siret.siege && this.siret.siege.ville ? this.siret.siege.ville : ''
        let pays = this.siret.siege && this.siret.siege.pays ? this.siret.siege.pays : ''
        return (adresse +
            ", " +
            codePostal +
            " " +
            ville + ', ' + pays
        );
      } else if (this.vat) {
        return (this.vat.address)
      }
    }
  },
  methods: {
    verifySiretThrottle: throttle(
      function() {
        this.verifySiret();
      },
      1000,
      true,
      true
    ),
    verifyVatIdThrottle: throttle(
      function() {
        this.verifyVatId();
      },
      1000,
      true,
      true
    ),
    onSiretChange() {
      console.log("onSiretChange");
      this.verifySiretThrottle();
    },
    onVATidChange() {
      console.log("onSiretChange");
      this.verifyVatIdThrottle();
    },
    verifySiret() {
      console.log("fetchData");
      this.numberOfSiretRequest++
      loadPageContent("/api/verify-siret/" + this.edit.company.siret)
        .then(response => {
          this.siret = response.data.siret;
          let vat = response.data.vat;
          if (this.siret) {
            this.edit.company.name = this.siret.nom_entreprise
            this.edit.company.taxId = this.siret.numero_tva_intracommunautaire
            let adresse = this.siret.siege && this.siret.siege.adresse_ligne_1 ? this.siret.siege.adresse_ligne_1 : ''
            let adresse2 = this.siret.siege && this.siret.siege.adresse_ligne_2 ? this.siret.siege.adresse_ligne_2 : ''
            let rue = this.siret.siege && this.siret.siege.type_voie && this.siret.siege.libelle_voie ? this.siret.siege.type_voie + ' ' + this.siret.siege.libelle_voie : ''
            let numero = this.siret.siege && this.siret.numero_voie ? this.siret.numero_voie : ''
            let codePostal = this.siret.siege && this.siret.siege.code_postal ? this.siret.siege.code_postal : ''
            let ville = this.siret.siege && this.siret.siege.ville ? this.siret.siege.ville : ''
            let pays = this.siret.siege && this.siret.siege.pays ? this.siret.siege.pays : ''
            // let raisonSociale = this.siret.nom_entreprise ? this.siret.nom_entreprise : ''
            // let formeJuridique = this.siret.forme_juridique ? this.siret.forme_juridique : ''
            // let numeroRcs = this.siret.numero_rcs ? this.siret.numero_rcs : ''
            // let capital = this.siret.capital ? this.siret.capital : ''
            // let siret = this.siret.siege && this.siret.siege.siret ? this.siret.siege.siret : ''
            // let vatNumber = this.siret.numero_tva_intracommunautaire ? this.siret.numero_tva_intracommunautaire : ''
            let latitude = this.siret.siege && this.siret.siege.latitude ? this.siret.siege.latitude : ''
            let longitude = this.siret.siege && this.siret.siege.longitude ? this.siret.siege.longitude : ''
            let location = this.siret.siege && this.siret.siege.longitude && this.siret.siege.latitude ? this.siret.siege.latitude + ',' + this.siret.siege.longitude : ''
            this.edit.company.addressName = (adresse +
              ", " +
                codePostal +
              " " +
              ville + ', ' + pays
            )
            this.edit.company.address = {
              adresse,
              adresse2,
              codePostal,
              numero,
              rue,
              pays,
              ville,
              location,
              "lat": latitude,
              "lng": longitude
            }
          } else if (vat && vat.valid) {
            this.edit.company.name = vat.name + ''
            this.edit.company.addressName = vat.address + ''
          }
          // console.log(this.content)
          // this.content = response
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.numberOfSiretRequest--
        })
      ;
    },
    verifyVatId() {
      loadPageContent("/api/verify-vat-id/" + this.edit.company.taxId)
        .then(response => {
          this.vat = response.data.vat;
          if (this.vat && this.vat.valid) {
            this.edit.company.name = this.vat.name + ''
            this.edit.company.addressName = this.vat.address + ''
          }
          // console.log(this.content)
          // this.content = response
        })
        .catch(e => {
          console.error(e);
        });
    }
  }
};
</script>
